import { AUTH_STATUS_CODES, cognitoConfig } from "../config/cognito.config";
import { AutodeskModulesStrings } from "../constants/autodesk-modules";
import { AutodeskRoles } from "../constants/autodesk-roles";
import { getAutodeskModule } from "../common/common-service";
// import { Auth, API } from 'aws-amplify';
import Auth from "@aws-amplify/auth";
// import API from "@aws-amplify/api";
import AWS from "aws-sdk";
import { Signer } from "@aws-amplify/core";
import { API_URLS } from "../config/api-urls";
import { getStoredUserAuth, getItem } from "../utils/LocalStorage";
import { environment } from "../../environment";
import { setUserAuth, setItem } from "../utils/LocalStorage";
import { objectToQueryString } from "../utils/convertor";

const cognitoidentity = new AWS.CognitoIdentity({
    apiVersion: "2014-06-30",
    region: cognitoConfig.userPool.region,
});
const userPoolLoginKey = `cognito-idp.${cognitoConfig.userPool.region}.amazonaws.com/${cognitoConfig.userPool.UserPoolId}`;
const baseUrl = `https://${environment.apiGatewayHost}`;
const adminBaseUrl = `https://${environment.apiGatewayHostAdmin}`;
const watchDogBaseUrl = `https://${environment.apiGatewayHostWatchDog}`;
const autoCompBaseUrl = `https://${environment.apiGatewayHostAutocomplete}`;
const financeBaseUrl = `https://${environment.apiGatewayHostFinance}`;
const blackSwanBaseUrl = `https://${environment.apiGatewayHostBlackSwan}`;
const checkupBaseUrl = `https://${environment.apiGatewayHostCheckup}`;
const fringeBaseUrl = `https://${environment.apiGatewayHostFringe}`;
const vaultBaseUrl = `https://${environment.apiGatewayHostVault}`;

const teAccrucialBaseUrl = `https://${environment.apiGatewayHostTEAccrucial}`;
const aipAccrucialBaseUrl = `https://${environment.apiGatewayHostAIPAccrucial}`;
const fbpAccrucialBaseUrl = `https://${environment.apiGatewayHostFBPAccrucial}`;

const awsCCForecastBaseUrl = `https://${environment.apiGatewayHostAWSCCForecast}`;

const taxTrendAnalysisBaseUrl = `https://${environment.apiGatewayHostTaxTrendAnalysis}`;

const leaseReconBaseUrl = `https://${environment.apiGatewayHostLeaseRecon}`;

const esgDashboardBaseUrl = `https://${environment.apiGatewayHostESGDashboard}`;

const acvForecastingDashboardBaseUrl = `https://${environment.apiGatewayHostACVForecastingDashboard}`;

const forecastPlatformBaseUrl = `https://${environment.apiGatewayHostForecastPlatform}`;

const applauseSpendPlatformBaseUrl = `https://${environment.apiGatewayHostApplauseSpendPlatform}`;

const payrollCashFundBaseUrl = `https://${environment.apiGatewayHostPayrollCashFund}`;

const taxReconBaseUrl = `https://${environment.apiGatewayHostTaxRecon}`;

const seesawBaseUrl = `https://${environment.apiGatewayHostSeesaw}`;

const meetingDigestBaseUrl = `https://${environment.apiGatewayHostMeetingDigest}`;

// const token = getStoredUserAuth()["autodesk_access_token"];
// const options = {
//     headers: {
//         "X-auth-token": token ? token : "",
//         "Content-Type": "application/json",
//     },
// };

async function updateConfigCredentials(customRoleArn, userToken) {
    const userPoolLoginKey = `cognito-idp.${cognitoConfig.userPool.region}.amazonaws.com/${cognitoConfig.userPool.UserPoolId}`;
    const credentials = await Auth.currentUserCredentials();
    const params = {
        IdentityId: credentials.identityId,
        Logins: {
            [userPoolLoginKey]: userToken
                ? userToken
                : getStoredUserAuth()["autodesk_access_token"],
        },
    };
    if (customRoleArn) {
        params.CustomRoleArn = customRoleArn;
    }
    const data = await cognitoidentity.getCredentialsForIdentity(params).promise();
    const cred = {
        accessKeyId: data.Credentials.AccessKeyId,
        sessionToken: data.Credentials.SessionToken,
        secretAccessKey: data.Credentials.SecretKey,
        expireTime: data.Credentials.Expiration,
        expired: false,
    };
    AWS.config.update({
        region: cognitoConfig.userPool.region,
        credentials: cred,
    });
}

// sign a request using Amplify Auth and Signer
async function amplifyRequest(url, method, data, host) {
    const user = await Auth.currentAuthenticatedUser();
    const userSession = user.signInUserSession;
    const token = userSession.idToken.jwtToken;

    // set your AWS region and service here
    const serviceInfo = {
        region: cognitoConfig.userPool.region,
        service: "execute-api",
    };

    const signParams = {
        url,
        data: method === "GET" ? null : JSON.stringify(data),
        host,
        method,
        path: url,
        headers: {
            "x-auth-token": token,
            "Content-Type": "application/json",
        },
    };

    if (AWS.config.credentials === null) {
        await updateConfigCredentials("", token);
    }

    const expTime = new Date(AWS.config.credentials.expireTime).getTime();
    if (expTime) {
        const timeDiff = expTime - Date.now();
        if (timeDiff < 0) {
            await refreshUserSession();
        }
    }

    const cred = {
        access_key: AWS.config.credentials.accessKeyId,
        secret_key: AWS.config.credentials.secretAccessKey,
        session_token: AWS.config.credentials.sessionToken,
    };

    const signedParams = await Signer.sign(signParams, cred, serviceInfo);

    try {
        const apiParams = {
            method,
            headers: signedParams.headers,
        };
        if (method !== "GET") {
            apiParams.body = JSON.stringify(data);
        }
        const resp = await fetch(signedParams.url, apiParams);
        if (resp.ok) return resp.json();
        return resp.json().then((response) => {
            throw new Error(JSON.stringify(response.data));
        });
        // if (resp.ok) {
        //     return await resp.json();
        // } else {
        //     throw new Error({ message: resp.status, data: await resp.json() });
        // }
    } catch (err) {
        throw err;
    }
}

async function handleLoginSuccess(token, setAuthStatus, data, groups) {
    if (validateUserAndRole(groups)) {
        await setAuthStatus(data);
        return AUTH_STATUS_CODES.signedIn;
    } else {
        return AUTH_STATUS_CODES.noSuchUser;
    }
}
async function checkRole(roles) {
    let roleArn;
    switch (getAutodeskModule()) {
        case AutodeskModulesStrings.PriceHub:
            if (roles.includes(AutodeskRoles.BIAAdmin1)) {
                roleArn = environment.biaAdminRole1Arn;
            } else if (roles.includes(AutodeskRoles.AgentAssistAdmin)) {
                roleArn = environment.AgentAssistAdminRoleArn;
            } else if (roles.includes(AutodeskRoles.AgentAssistUser)) {
                roleArn = environment.AgentAssistUserRoleArn;
            } else if (roles.includes(AutodeskRoles.SSOLoginUserDev)) {
                roleArn = environment.AgentAssistUserRoleArn;
            } else if (roles.includes(AutodeskRoles.SSOLoginUserProd)) {
                roleArn = environment.AgentAssistUserRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.WatchDog:
            if (roles.includes(AutodeskRoles.BIAAdmin1)) {
                roleArn = environment.biaAdminRole1Arn;
            } else if (roles.includes(AutodeskRoles.WatchdogAdmin)) {
                roleArn = environment.WatchdogAdminRoleArn;
            } else if (roles.includes(AutodeskRoles.WatchdogUser)) {
                roleArn = environment.WatchdogUserRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.SalesDashboard:
            if (roles.includes(AutodeskRoles.BIAAdmin1)) {
                roleArn = environment.biaAdminRole1Arn;
            } else if (roles.includes(AutodeskRoles.SalesDashboardAdmin)) {
                roleArn = environment.SalesDashboardAdminRoleArn;
            } else if (roles.includes(AutodeskRoles.SalesDashboardUser)) {
                roleArn = environment.SalesDashboardUserRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.ExpenseDashboard:
            if (roles.includes(AutodeskRoles.BIAAdmin1)) {
                roleArn = environment.biaAdminRole1Arn;
            } else if (roles.includes(AutodeskRoles.ExpensesDashboardAdmin)) {
                roleArn = environment.ExpensesDashboardAdminRoleArn;
            } else if (roles.includes(AutodeskRoles.ExpensesDashboardUser)) {
                roleArn = environment.ExpensesDashboardUserRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.BlackSwan:
            if (roles.includes(AutodeskRoles.BIAAdmin1)) {
                roleArn = environment.biaAdminRole1Arn;
            } else if (roles.includes(AutodeskRoles.BlackSwanAdmin)) {
                roleArn = environment.BlackSwanAdminRoleArn;
            } else if (roles.includes(AutodeskRoles.BlackSwanUser)) {
                roleArn = environment.BlackSwanUserRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.Healthcheck:
            if (roles.includes(AutodeskRoles.BIAAdmin1)) {
                roleArn = environment.biaAdminRole1Arn;
            } else if (roles.includes(AutodeskRoles.HealthcheckAdmin)) {
                roleArn = environment.HealthcheckAdminRoleArn;
            } else if (roles.includes(AutodeskRoles.HealthcheckUser)) {
                roleArn = environment.HealthcheckUserRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.Fringe:
            if (roles.includes(AutodeskRoles.BIAAdmin1)) {
                roleArn = environment.biaAdminRole1Arn;
            } else if (roles.includes(AutodeskRoles.FringeAdmin)) {
                roleArn = environment.FringeAdminRoleArn;
            } else if (roles.includes(AutodeskRoles.FringeUser)) {
                roleArn = environment.FringeUserRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.Vault:
            if (roles.includes(AutodeskRoles.BIAAdmin1)) {
                roleArn = environment.biaAdminRole1Arn;
            } else if (roles.includes(AutodeskRoles.VaultAdmin)) {
                roleArn = environment.VaultAdminRoleArn;
            } else if (roles.includes(AutodeskRoles.VaultUser)) {
                roleArn = environment.VaultUserRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.TEAccrucial:
            if (roles.includes(AutodeskRoles.BIAAdmin1)) {
                roleArn = environment.biaAdminRole1Arn;
            } else if (roles.includes(AutodeskRoles.TEAccrucialAdmin)) {
                roleArn = environment.TEAccrucialAdminRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.AIPAccrucial:
            if (roles.includes(AutodeskRoles.BIAAdmin1)) {
                roleArn = environment.biaAdminRole1Arn;
            } else if (roles.includes(AutodeskRoles.AIPAccrucialAdmin)) {
                roleArn = environment.AIPAccrucialAdminRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.FBPAccrucial:
            if (roles.includes(AutodeskRoles.BIAAdmin2)) {
                roleArn = environment.biaAdminRole2Arn;
            } else if (roles.includes(AutodeskRoles.FBPAccrucialAdmin)) {
                roleArn = environment.FBPAccrucialAdminRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.AWSCCForecast:
            if (roles.includes(AutodeskRoles.BIAAdmin2)) {
                roleArn = environment.biaAdminRole2Arn;
            } else if (roles.includes(AutodeskRoles.AWSCCForecastAdmin)) {
                roleArn = environment.AWSCCForecastAdminRoleArn;
            } else if (roles.includes(AutodeskRoles.AWSCCForecastUser)) {
                roleArn = environment.AWSCCForecastUserRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.TaxTrendAnalysis:
            if (roles.includes(AutodeskRoles.BIAAdmin2)) {
                roleArn = environment.biaAdminRole2Arn;
            } else if (roles.includes(AutodeskRoles.TaxTrendAnalysisAdmin)) {
                roleArn = environment.TaxTrendAnalysisAdminRoleArn;
            } else if (roles.includes(AutodeskRoles.TaxTrendAnalysisUser)) {
                roleArn = environment.TaxTrendAnalysisUserRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.LeaseRecon:
            if (roles.includes(AutodeskRoles.BIAAdmin2)) {
                roleArn = environment.biaAdminRole2Arn;
            } else if (roles.includes(AutodeskRoles.LeaseReconAdmin)) {
                roleArn = environment.LeaseReconAdminRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.ESGDashboard:
            if (roles.includes(AutodeskRoles.BIAAdmin2)) {
                roleArn = environment.biaAdminRole2Arn;
            } else if (roles.includes(AutodeskRoles.ESGDashboardAdmin)) {
                roleArn = environment.ESGDashboardAdminRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.ACVForecastingDashboardTopline:
            if (roles.includes(AutodeskRoles.BIAAdmin3)) {
                roleArn = environment.biaAdminRole3Arn;
            } else if (roles.includes(AutodeskRoles.ACVForecastingDashboardAdminTopline)) {
                roleArn = environment.ACVForecastingDashboardAdminRoleArnTopline;
            }
            return roleArn;
        case AutodeskModulesStrings.ACVForecastingDashboardAMER:
            if (roles.includes(AutodeskRoles.BIAAdmin3)) {
                roleArn = environment.biaAdminRole3Arn;
            } else if (roles.includes(AutodeskRoles.ACVForecastingDashboardAdminAMER)) {
                roleArn = environment.ACVForecastingDashboardAdminRoleArnAMER;
            }
            return roleArn;
        case AutodeskModulesStrings.ForecastPlatform:
            if (roles.includes(AutodeskRoles.BIAAdmin2)) {
                roleArn = environment.biaAdminRole2Arn;
            } else if (roles.includes(AutodeskRoles.ForecastPlatformAdmin)) {
                roleArn = environment.ForecastPlatformAdminRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.ApplauseSpendPlatform:
            if (roles.includes(AutodeskRoles.BIAAdmin2)) {
                roleArn = environment.biaAdminRole2Arn;
            } else if (roles.includes(AutodeskRoles.ApplauseSpendPlatformUser)) {
                roleArn = environment.ApplauseSpendPlatformUserRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.CashFundForecast:
            if (roles.includes(AutodeskRoles.BIAAdmin2)) {
                roleArn = environment.biaAdminRole2Arn;
            } else if (roles.includes(AutodeskRoles.CashFundForecastAdmin)) {
                roleArn = environment.PayrollCashFundAdminRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.TaxRecon:
            if (roles.includes(AutodeskRoles.BIAAdmin3)) {
                roleArn = environment.biaAdminRole3Arn;
            } else if (roles.includes(AutodeskRoles.TaxReconAdmin)) {
                roleArn = environment.TaxReconAdminRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.Seesaw:
            if (roles.includes(AutodeskRoles.BIAAdmin3)) {
                roleArn = environment.biaAdminRole3Arn;
            } else if (roles.includes(AutodeskRoles.SeesawAdmin)) {
                roleArn = environment.SeesawAdminRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.MeetingDigest:
            if (roles.includes(AutodeskRoles.BIAAdmin3)) {
                roleArn = environment.biaAdminRole3Arn;
            } else if (roles.includes(AutodeskRoles.MeetingDigestAdmin)) {
                roleArn = environment.MeetingDigestAdminRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.LumiTopics:
            if (roles.includes(AutodeskRoles.BIAAdmin3)) {
                roleArn = environment.biaAdminRole3Arn;
            } else if (roles.includes(AutodeskRoles.LumiTopicsAdmin)) {
                roleArn = environment.LumiTopicsAdminRoleArn;
            }
            return roleArn;
        case AutodeskModulesStrings.Finbot:
            if (roles.includes(AutodeskRoles.BIAAdmin3)) {
                roleArn = environment.biaAdminRole3Arn;
            } else if (roles.includes(AutodeskRoles.FinbotAdmin)) {
                roleArn = environment.FinbotAdminRoleArn;
            } else if (roles.includes(AutodeskRoles.FinbotUser)) {
                roleArn = environment.FinbotUserRoleArn;
            }
            return roleArn;
    }
    return false;
}
export async function validateUserAndRole(roles) {
    switch (getAutodeskModule()) {
        case AutodeskModulesStrings.PriceHub:
            if (
                roles.includes(AutodeskRoles.AgentAssistAdmin) ||
                roles.includes(AutodeskRoles.AgentAssistUser) ||
                roles.includes(AutodeskRoles.SSOLoginUserDev) ||
                roles.includes(AutodeskRoles.SSOLoginUserProd) ||
                roles.includes(AutodeskRoles.BIAAdmin1) ||
                roles.includes(AutodeskRoles.BIAAdmin2) ||
                roles.includes(AutodeskRoles.BIAAdmin3)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.WatchDog:
            if (
                roles.includes(AutodeskRoles.WatchdogAdmin) ||
                roles.includes(AutodeskRoles.WatchdogUser) ||
                roles.includes(AutodeskRoles.BIAAdmin1)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.SalesDashboard:
            if (
                roles.includes(AutodeskRoles.SalesDashboardAdmin) ||
                roles.includes(AutodeskRoles.SalesDashboardUser) ||
                roles.includes(AutodeskRoles.BIAAdmin1)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.ExpenseDashboard:
            if (
                roles.includes(AutodeskRoles.ExpensesDashboardAdmin) ||
                roles.includes(AutodeskRoles.ExpensesDashboardUser) ||
                roles.includes(AutodeskRoles.BIAAdmin1)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.BlackSwan:
            if (
                roles.includes(AutodeskRoles.BlackSwanAdmin) ||
                roles.includes(AutodeskRoles.BlackSwanUser) ||
                roles.includes(AutodeskRoles.BIAAdmin1)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.Healthcheck:
            if (
                roles.includes(AutodeskRoles.HealthcheckAdmin) ||
                roles.includes(AutodeskRoles.HealthcheckUser) ||
                roles.includes(AutodeskRoles.BIAAdmin1)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.Fringe:
            if (
                roles.includes(AutodeskRoles.FringeAdmin) ||
                roles.includes(AutodeskRoles.FringeUser) ||
                roles.includes(AutodeskRoles.BIAAdmin1)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.Vault:
            if (
                roles.includes(AutodeskRoles.VaultAdmin) ||
                roles.includes(AutodeskRoles.VaultUser) ||
                roles.includes(AutodeskRoles.BIAAdmin1)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.TEAccrucial:
            if (
                roles.includes(AutodeskRoles.TEAccrucialAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin1)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.AIPAccrucial:
            if (
                roles.includes(AutodeskRoles.AIPAccrucialAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin1)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.FBPAccrucial:
            if (
                roles.includes(AutodeskRoles.FBPAccrucialAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.AWSCCForecast:
            if (
                roles.includes(AutodeskRoles.AWSCCForecastAdmin) ||
                roles.includes(AutodeskRoles.AWSCCForecastUser) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.TaxTrendAnalysis:
            if (
                roles.includes(AutodeskRoles.TaxTrendAnalysisAdmin) ||
                roles.includes(AutodeskRoles.TaxTrendAnalysisUser) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.LeaseRecon:
            if (
                roles.includes(AutodeskRoles.LeaseReconAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.ESGDashboard:
            if (
                roles.includes(AutodeskRoles.ESGDashboardAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.ACVForecastingDashboardTopline:
            if (
                roles.includes(AutodeskRoles.ACVForecastingDashboardAdminTopline) ||
                roles.includes(AutodeskRoles.BIAAdmin3)
            ) {
                return true;
            } else {
                return false;
            }

        case AutodeskModulesStrings.ACVForecastingDashboardAMER:
            if (
                roles.includes(AutodeskRoles.ACVForecastingDashboardAdminAMER) ||
                roles.includes(AutodeskRoles.BIAAdmin3)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.ForecastPlatform:
            if (
                roles.includes(AutodeskRoles.ForecastPlatformAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.ApplauseSpendPlatform:
            if (
                roles.includes(AutodeskRoles.ApplauseSpendPlatformUser) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.CashFundForecast:
            if (
                roles.includes(AutodeskRoles.CashFundForecastAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.TaxRecon:
            if (
                roles.includes(AutodeskRoles.TaxReconAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin3)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.Seesaw:
            if (
                roles.includes(AutodeskRoles.SeesawAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin3)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.MeetingDigest:
            if (
                roles.includes(AutodeskRoles.MeetingDigestAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin3)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.LumiTopics:
            if (
                roles.includes(AutodeskRoles.LumiTopicsAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin3)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.Finbot:
            if (
                roles.includes(AutodeskRoles.FinbotUser) ||
                roles.includes(AutodeskRoles.FinbotAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin3)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.IRBot:
            if (
                roles.includes(AutodeskRoles.IRBotUser) ||
                roles.includes(AutodeskRoles.IRBotAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin3)
            ) {
                return true;
            } else {
                return false;
            }
    }
    return false;
}

export async function checkIsAdmin(roles) {
    switch (getAutodeskModule()) {
        case AutodeskModulesStrings.PriceHub:
            if (
                roles.includes(AutodeskRoles.AgentAssistAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin1) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.WatchDog:
            if (
                roles.includes(AutodeskRoles.WatchdogAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin1) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.SalesDashboard:
            if (
                roles.includes(AutodeskRoles.SalesDashboardAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin1) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.ExpenseDashboard:
            if (
                roles.includes(AutodeskRoles.ExpensesDashboardAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin1) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.BlackSwan:
            if (
                roles.includes(AutodeskRoles.BlackSwanAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin1) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.Healthcheck:
            if (
                roles.includes(AutodeskRoles.HealthcheckAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin1) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.Fringe:
            if (
                roles.includes(AutodeskRoles.FringeAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin1) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.Vault:
            if (
                roles.includes(AutodeskRoles.VaultAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin1) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.AWSCCForecast:
            if (
                roles.includes(AutodeskRoles.AWSCCForecastAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin1) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.TaxTrendAnalysis:
            if (
                roles.includes(AutodeskRoles.TaxTrendAnalysisAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin1) ||
                roles.includes(AutodeskRoles.BIAAdmin2)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.Finbot:
            if (
                roles.includes(AutodeskRoles.FinbotAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin1) ||
                roles.includes(AutodeskRoles.BIAAdmin2) ||
                roles.includes(AutodeskRoles.BIAAdmin3)
            ) {
                return true;
            } else {
                return false;
            }
        case AutodeskModulesStrings.IRBot:
            if (
                roles.includes(AutodeskRoles.IRBotAdmin) ||
                roles.includes(AutodeskRoles.BIAAdmin1) ||
                roles.includes(AutodeskRoles.BIAAdmin2) ||
                roles.includes(AutodeskRoles.BIAAdmin3)
            ) {
                return true;
            } else {
                return false;
            }
    }
    return false;
}

export async function switchRoles(region, roles, cognitoId, token) {
    let customRoleArn;
    // const userPoolLoginKey = `cognito-idp.${region}.amazonaws.com/${cognitoId}`;
    // const credentials = await Auth.currentUserCredentials();
    // let params = {
    //     IdentityId: credentials.identityId,
    //     Logins: {
    //         [userPoolLoginKey]: token,
    //     },
    // };
    if (getItem("ad_module")) {
        customRoleArn = await checkRole(roles);
        // params = {
        //     IdentityId: credentials.identityId,
        //     CustomRoleArn: await checkRole(roles),
        //     Logins: {
        //         [userPoolLoginKey]: token,
        //     },
        // };
    }

    try {
        await updateConfigCredentials(customRoleArn, token);
        // const data = await cognitoidentity.getCredentialsForIdentity(params).promise();
        // const cred = {
        //     accessKeyId: data.Credentials.AccessKeyId,
        //     sessionToken: data.Credentials.SessionToken,
        //     secretAccessKey: data.Credentials.SecretKey,
        //     expireTime: data.Credentials.Expiration,
        //     expired: false,
        // };
        // AWS.config.update({
        //     region,
        //     credentials: cred,
        // });

        return true;
    } catch (err) {
        console.log(err, err.stack);
        return null;
    }
}

export async function authenticateUserSSO(setAuthStatus) {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userSession = user.signInUserSession;
        const cognitoGrp = userSession.idToken.payload["cognito:groups"];

        const authData = {
            userId: userSession.idToken.payload.sub,
            username: user.attributes.email,
            autodesk_access_token: userSession.idToken.jwtToken,
            autodesk_exp: userSession.idToken.payload.exp,
            idToken: userSession.idToken.jwtToken,
            authenticated: true,
        };
        setAuthStatus(authData);
        await switchRoles(
            cognitoConfig.userPool.region,
            cognitoGrp,
            cognitoConfig.userPool.UserPoolId,
            userSession.idToken.jwtToken,
        );
        return {
            auth: true,
            user: authData,
            groups: cognitoGrp,
        };
    } catch (error) {
        return {
            auth: false,
            error,
        };
    }
}

export async function authenticateUserSSOByPassCache(setAuthStatus) {
    try {
        // const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        const user = await Auth.currentUserPoolUser({ bypassCache: true });

        // const user = await Auth.currentAuthenticatedUser();
        // console.log(user);
        const userSession = user.signInUserSession;
        // const refreshToken = userSession.getRefreshToken();
        // await user.refreshSession(refreshToken, async (err, session) => {
        //     await user.setSignInUserSession(session);
        // });
        const cognitoGrp = userSession.idToken.payload["cognito:groups"];

        const authData = {
            userId: userSession.idToken.payload.sub,
            username: user.attributes.email,
            autodesk_access_token: userSession.idToken.jwtToken,
            autodesk_exp: userSession.idToken.payload.exp,
            idToken: userSession.idToken.jwtToken,
            authenticated: true,
        };
        setAuthStatus(authData);
        await switchRoles(
            cognitoConfig.userPool.region,
            cognitoGrp,
            cognitoConfig.userPool.UserPoolId,
            userSession.idToken.jwtToken,
        );
        return {
            auth: true,
            user: authData,
            groups: cognitoGrp,
        };
    } catch (error) {
        return {
            auth: false,
            error,
        };
    }
}

export async function refreshUserSession() {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const userSession = user.signInUserSession;
        const refreshToken = userSession.getRefreshToken();
        const cognitoGrp = userSession.idToken.payload["cognito:groups"];
        user.refreshSession(refreshToken, (err, session) => {
            user.setSignInUserSession(session);
        });

        const authData = {
            userId: userSession.idToken.payload.sub,
            username: user.attributes.email,
            autodesk_access_token: userSession.idToken.jwtToken,
            autodesk_exp: userSession.idToken.payload.exp,
            idToken: userSession.idToken.jwtToken,
            authenticated: true,
        };
        setUserAuth(authData);
        await switchRoles(
            cognitoConfig.userPool.region,
            cognitoGrp,
            cognitoConfig.userPool.UserPoolId,
            userSession.idToken.jwtToken,
        );
        return true;
    } catch (error) {
        return {
            auth: false,
            error,
        };
    }
}

export async function authenticateUser(user, setAuthStatus) {
    try {
        const result = await Auth.signIn(user.email, user.password);
        // console.log("result", result)

        if (result["challengeName"] === AUTH_STATUS_CODES.newPasswordRequired) {
            return result["challengeName"];
        } else {
            const userSession = result.signInUserSession;
            // console.log(userSession)
            const cognitoGrp = userSession.accessToken.payload["cognito:groups"];

            const authData = {
                userId: userSession.idToken.payload.sub,
                username: user.email,
                autodesk_access_token: userSession.accessToken.jwtToken,
                autodesk_exp: userSession.accessToken.payload.exp,
                idToken: userSession.idToken.jwtToken,
                authenticated: true,
            };
            const status = await handleLoginSuccess(
                userSession.accessToken.jwtToken,
                setAuthStatus,
                authData,
                cognitoGrp,
            );
            // console.log(status)
            return status;
        }
    } catch (error) {
        throw error;
    }
}

export async function completeNewPassword(confirmPasswordData) {
    try {
        const user = await Auth.signIn(confirmPasswordData.username, confirmPasswordData.password);
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            const { userAttributes } = user.challengeParam;
            delete userAttributes.email_verified;
            userAttributes.name = confirmPasswordData.username;

            const compPwd = await Auth.completeNewPassword(
                user,
                confirmPasswordData.newPassword,
                userAttributes,
            );
            return compPwd;
        }
    } catch (error) {
        throw error;
    }
}

export async function forgotPassword(username) {
    try {
        const forgotPwd = await Auth.forgotPassword(username);
        return AUTH_STATUS_CODES.verificationCodeRequired;
    } catch (error) {
        throw error;
    }
}

export async function forgotPasswordSubmit(username, code, newPassword) {
    try {
        const forgotPwdSubmit = await Auth.forgotPasswordSubmit(username, code, newPassword);
        return AUTH_STATUS_CODES.success;
    } catch (error) {
        throw error;
    }
}

export async function signOut() {
    try {
        await Auth.signOut();
    } catch (error) {
        // console.log('error signing out: ', error);
        throw error;
    }
}

/** Home page APIs */
export async function getStatistics() {
    try {
        const getURL = API_URLS.landingPageUrls.retrieveStatisticsData;
        return await amplifyRequest(`${baseUrl}${getURL}`, "GET", {}, environment.apiGatewayHost);
        // return await API.get(
        //     "getStatistics",
        //     API_URLS.landingPageUrls.retrieveStatisticsData,
        //     options,
        // );
    } catch (error) {
        throw error;
    }
}

export async function sendFeedback(body) {
    try {
        const postURL = `${API_URLS.landingPageUrls.sendModulesFeedback}`;
        return await amplifyRequest(
            `${adminBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHost,
        );
        // return await API.post("sendFeedback", postURL, { ...options, ...{ body } });
    } catch (error) {
        throw error;
    }
}

export async function sendAccessRequest(body) {
    try {
        const postURL = `${API_URLS.landingPageUrls.sendAccessRequest}`;
        return await amplifyRequest(
            `${adminBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHost,
        );
        // return await API.post("sendFeedback", postURL, { ...options, ...{ body } });
    } catch (error) {
        throw error;
    }
}

/** Home Page APIs ends here */

/** Price Listing APIs */

export async function getPriceListing(date, limit, page, type) {
    try {
        const getURL = date
            ? `${API_URLS.configUrls.retrieveGoldenFileURL}?date=${date}&limit=${limit}&page=${page}&type=${type}`
            : `${API_URLS.configUrls.retrieveGoldenFileURL}?limit=${limit}&page=${page}&type=${type}`;
        return await amplifyRequest(`${baseUrl}${getURL}`, "GET", {}, environment.apiGatewayHost);
        // return await API.get("getGoldenRows", getURL, options);
    } catch (error) {
        throw error;
    }
}

export async function getPriceListingSearch(date, limit, page, body, type) {
    try {
        const postURL = date
            ? `${API_URLS.configUrls.searchURL}?date=${date}&limit=${limit}&page=${page}&type=${type}`
            : `${API_URLS.configUrls.searchURL}?limit=${limit}&page=${page}&type=${type}`;
        return await amplifyRequest(
            `${baseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHost,
        );
        // return await API.post("getPriceListingSearch", postURL, { ...options, ...{ body } });
    } catch (error) {
        throw error;
    }
}

export async function getDropDownConfig(date, body, type) {
    try {
        const postURL = date
            ? `${API_URLS.configUrls.dropdownConfigURL}?date=${date}&type=${type}`
            : API_URLS.configUrls.dropdownConfigURL;
        return await amplifyRequest(
            `${baseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHost,
        );
        // return await API.post("getDropDownConfig", postURL, { ...options, ...{ body } });
    } catch (error) {
        throw error;
    }
}

export async function getProcessedDates(type) {
    try {
        const getURL = `${API_URLS.configUrls.retrieveProcessedDates}?type=${type}`;
        return await amplifyRequest(`${baseUrl}${getURL}`, "GET", {}, environment.apiGatewayHost);
        // return await API.get(
        //     "getProcessedDates",
        //     API_URLS.configUrls.retrieveProcessedDates,
        //     options,
        // );
    } catch (error) {
        throw error;
    }
}

export async function getAutoCompSearch(query) {
    try {
        const getURL = `${API_URLS.configUrls.retrieveAutoCompData}`;
        return await amplifyRequest(
            `${autoCompBaseUrl}${getURL}`,
            "POST",
            { query },
            environment.apiGatewayHostAutocomplete,
        );
        // return await API.get("getAutoCompSearch", getURL, options);
    } catch (error) {
        throw error;
    }
}

export async function getRecentSearch(userId) {
    try {
        const postURL = `${API_URLS.configUrls.retrieveRecentSearch}`;
        return await amplifyRequest(
            `${autoCompBaseUrl}${postURL}`,
            "POST",
            { "x-request-user": userId },
            environment.apiGatewayHostAutocomplete,
        );
        // return await API.post("getRecentSearch", postURL, {
        //     ...options,
        //     body: { "x-request-user": userId },
        // });
    } catch (error) {
        throw error;
    }
}

export async function getTopSearch() {
    try {
        const getURL = `${API_URLS.configUrls.retrieveTopSearch}`;
        return await amplifyRequest(
            `${autoCompBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostAutocomplete,
        );
        // return await API.get("getTopSearch", getURL, options);
    } catch (error) {
        throw error;
    }
}

export async function addRecentSearch(body) {
    try {
        const postURL = `${API_URLS.configUrls.addRecentSearch}`;
        return await amplifyRequest(
            `${autoCompBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostAutocomplete,
        );
        // return await API.post("addRecentSearch", postURL, { ...options, ...{ body } });
    } catch (error) {
        throw error;
    }
}

export async function exportReport(date, body, type) {
    try {
        const postURL = date
            ? `${API_URLS.configUrls.exportURL}?date=${date}&limit=-1&page=-1&type=${type}`
            : `${API_URLS.configUrls.exportURL}?limit=-1&page=-1&type=${type}`;
        return await amplifyRequest(
            `${baseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHost,
        );
        // return await API.post("exportReport", postURL, { ...options, ...{ body } });
    } catch (error) {
        throw error;
    }
}

export async function getExportReportUrl(exportId) {
    try {
        const getURL = `${API_URLS.configUrls.exportURL}/${exportId}`;
        return await amplifyRequest(`${baseUrl}${getURL}`, "GET", {}, environment.apiGatewayHost);
        // return await API.get("getExportReportUrl", getURL, options);
    } catch (error) {
        throw error;
    }
}

/** Price Lisiting APIs ends here */

/** WatchDog APIs */

export async function getOWSAlertDetails(owsId) {
    try {
        const getURL = `${API_URLS.watchDogConfigUrls.owsData}/${owsId}`;
        return await amplifyRequest(
            `${watchDogBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostWatchDog,
        );
        // return await API.get('getOWSAlertDetails', getURL, options);
    } catch (error) {
        throw error;
    }
}

export async function searchWatchDogDetails(body) {
    try {
        const postURL = `${API_URLS.watchDogConfigUrls.search}/`;
        return await amplifyRequest(
            `${watchDogBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostWatchDog,
        );
        // return await API.post('searchWatchDogDetails', postURL, {...options, ...{body}});
    } catch (error) {
        throw error;
    }
}

export async function getRecentSearchWatchDog(params) {
    try {
        const getURL = `${API_URLS.watchDogConfigUrls.search}/?page=${params.page}&limit=${params.limit}`;
        return await amplifyRequest(
            `${watchDogBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostWatchDog,
        );
        // return await API.get('getRecentSearchWatchDog', getURL, options);
    } catch (error) {
        throw error;
    }
}

export async function getIndividualRecentSearchWatchDog(id) {
    try {
        const getURL = `${API_URLS.watchDogConfigUrls.search}/${id}`;
        return await amplifyRequest(
            `${watchDogBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostWatchDog,
        );
        // return await API.get('getIndividualRecentSearchWatchDog', getURL, options);
    } catch (error) {
        throw error;
    }
}

export async function getWatchDogReportUrls(id) {
    try {
        const getURL = `${API_URLS.watchDogConfigUrls.search}/${id}/result`;
        return await amplifyRequest(
            `${watchDogBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostWatchDog,
        );
        // return await API.get('getWatchDogReportUrls', getURL, options);
    } catch (error) {
        throw error;
    }
}

export async function getWatchDogSearchStatus(id) {
    try {
        const getURL = `${API_URLS.watchDogConfigUrls.search}/${id}/status`;
        return await amplifyRequest(
            `${watchDogBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostWatchDog,
        );
        // return await API.get('getWatchDogSearchStatus', getURL, options);
    } catch (error) {
        throw error;
    }
}

export async function searchWatchDogRecentSearch(params) {
    try {
        const getURL = `${API_URLS.watchDogConfigUrls.search}/recent?search=${params.search}&page=${params.page}&limit=${params.limit}`;
        return await amplifyRequest(
            `${watchDogBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostWatchDog,
        );
        // return await API.get('searchWatchDogRecentSearch', getURL, options);
    } catch (error) {
        throw error;
    }
}

export async function getOWSAlertData(createdAt, alertId, page, limit, sortCol) {
    try {
        const getURL = `${
            API_URLS.watchDogConfigUrls.owsEntityReport
        }?search=${alertId}&created_date=${createdAt}&page=${page}&limit=${limit}${
            sortCol
                ? `&ordering=${sortCol.sortByAscending ? sortCol.selector : "-" + sortCol.selector}`
                : ""
        }`;
        return await amplifyRequest(
            `${watchDogBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostWatchDog,
        );
        // return await API.get('getOWSAlertData', getURL, options);
    } catch (error) {
        throw error;
    }
}

export async function exportOWSAlertData(date) {
    try {
        const getURL = `${API_URLS.watchDogConfigUrls.owsReportExport}?created_date=${date}`;
        return await amplifyRequest(
            `${watchDogBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostWatchDog,
        );
        // return await API.get('exportOWSAlertData', getURL, options);
    } catch (error) {
        throw error;
    }
}

/** WatchDog APIs ends here */

/** Sale Dashboard APIs */

export async function getSalesFilters(date) {
    try {
        const getURL = `${API_URLS.salesDashboardUrls.retrieveFilters}`;
        return await amplifyRequest(
            `${financeBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostFinance,
        );
        // return await API.get("getSalesFilters", getURL, options);
    } catch (error) {
        throw error;
    }
}

export async function getSalesForecast(body) {
    try {
        const postURL = `${API_URLS.salesDashboardUrls.retrieveForecastData}`;
        return await amplifyRequest(
            `${financeBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostFinance,
        );
        // return await API.post("getSalesForecast", postURL, { ...options, ...{ body } });
    } catch (error) {
        throw error;
    }
}

export async function getSalesForecastStatus(statusId) {
    try {
        const getURL = `${API_URLS.salesDashboardUrls.retrieveForecastData}/status?id=${statusId}`;
        return await amplifyRequest(
            `${financeBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostFinance,
        );
        // return await API.post("getSalesForecast", postURL, { ...options, ...{ body } });
    } catch (error) {
        throw error;
    }
}

/** Sales Dashboard APIs ends here */

/** Expense Dashboard APIs */

export async function getExpenseForecast(body) {
    // queryStringParameters: { groupname: "expenses_dashboard_admin" }
    try {
        const postURL = `${API_URLS.expensesDashboardUrls.retrieveForecastData}`;
        return await amplifyRequest(
            `${financeBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostFinance,
        );
        // return await API.post('getExpenseForecast', postURL, {...options, ...{body}});
    } catch (error) {
        throw error;
    }
}

/** Expense Dashboard APIs ends here */

/** Black Swan APIs */

export async function uploadFile(file_name) {
    try {
        const getURL = `${API_URLS.blackSwanUrls.uploadFile}?file_name=${file_name}`;
        return await amplifyRequest(
            `${blackSwanBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostBlackSwan,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function uploadFileComplete(body) {
    try {
        const postURL = `${API_URLS.blackSwanUrls.uploadFile}/complete`;
        return await amplifyRequest(
            `${blackSwanBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostBlackSwan,
        );
    } catch (error) {
        throw error;
    }
}

export async function processUploadedFile(body) {
    try {
        const getURL = `${API_URLS.blackSwanUrls.processUploadedFile}`;
        return await amplifyRequest(
            `${blackSwanBaseUrl}${getURL}`,
            "POST",
            body,
            environment.apiGatewayHostBlackSwan,
        );
    } catch (error) {
        throw error;
    }
}

export async function getProcessStatus(file_id) {
    try {
        const getURL = `${API_URLS.blackSwanUrls.getProcessStatus}?file_id=${file_id}`;
        return await amplifyRequest(
            `${blackSwanBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostBlackSwan,
        );
    } catch (error) {
        throw error;
    }
}

export async function getAnomalies() {
    try {
        const getURL = `${API_URLS.blackSwanUrls.getAnomalies}`;
        return await amplifyRequest(
            `${blackSwanBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostBlackSwan,
        );
    } catch (error) {
        throw error;
    }
}

export async function getUploadedFiles() {
    try {
        const getURL = `${API_URLS.blackSwanUrls.getUploadedFiles}`;
        return await amplifyRequest(
            `${blackSwanBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostBlackSwan,
        );
    } catch (error) {
        throw error;
    }
}

export async function getAnomaliesData(file_id, page, limit, body) {
    try {
        const getURL = `${API_URLS.blackSwanUrls.getAnomaliesData}?file_id=${file_id}&page=${page}&limit=${limit}`;
        return await amplifyRequest(
            `${blackSwanBaseUrl}${getURL}`,
            "POST",
            body,
            environment.apiGatewayHostBlackSwan,
        );
    } catch (error) {
        throw error;
    }
}

export async function saveAnomaliesData(file_id, body) {
    try {
        const getURL = `${API_URLS.blackSwanUrls.updateAnomalyData}?file_id=${file_id}`;
        return await amplifyRequest(
            `${blackSwanBaseUrl}${getURL}`,
            "POST",
            body,
            environment.apiGatewayHostBlackSwan,
        );
    } catch (error) {
        throw error;
    }
}

export async function exportAnomalies(file_id, page, limit, body) {
    try {
        const getURL = `${API_URLS.blackSwanUrls.exportURL}?file_id=${file_id}&page=${page}&limit=${limit}`;
        return await amplifyRequest(
            `${blackSwanBaseUrl}${getURL}`,
            "POST",
            body,
            environment.apiGatewayHostBlackSwan,
        );
    } catch (error) {
        throw error;
    }
}

export async function getColumnValidStatus(file_id) {
    try {
        const getURL = `${API_URLS.blackSwanUrls.columnValidStatus}?file_id=${file_id}`;
        return await amplifyRequest(
            `${blackSwanBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostBlackSwan,
        );
    } catch (error) {
        throw error;
    }
}

/** Black Swan APIs ends here */

/** Checkup APIs starts here */

export async function getCheckupMasterData(body) {
    try {
        const getURL = `${API_URLS.checkupUrls.getMasterData}`;
        return await amplifyRequest(
            `${checkupBaseUrl}${getURL}`,
            "POST",
            body,
            environment.apiGatewayHostCheckup,
        );
    } catch (error) {
        throw error;
    }
}

export async function getRepList() {
    try {
        const getURL = `${API_URLS.checkupUrls.getRepList}`;
        return await amplifyRequest(
            `${checkupBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostCheckup,
        );
    } catch (error) {
        throw error;
    }
}

export async function getChartsAccountDetails(agrr_num) {
    try {
        const getURL = `${API_URLS.checkupUrls.getChartsData}?agrr_num=${agrr_num}`;
        return await amplifyRequest(
            `${checkupBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostCheckup,
        );
    } catch (error) {
        throw error;
    }
}

export async function uploadCheckupFile(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.checkupUrls.upload}?${queryString}`;
        return await amplifyRequest(
            `${checkupBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostBlackSwan,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function uploadCheckupFileComplete(body) {
    try {
        const postURL = `${API_URLS.checkupUrls.uploadComplete}`;
        return await amplifyRequest(
            `${checkupBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostBlackSwan,
        );
    } catch (error) {
        throw error;
    }
}

export async function getExportMasterData(body) {
    try {
        const getURL = `${API_URLS.checkupUrls.exportMasterData}`;
        return await amplifyRequest(
            `${checkupBaseUrl}${getURL}`,
            "POST",
            body,
            environment.apiGatewayHostCheckup,
        );
    } catch (error) {
        throw error;
    }
}

export async function getUploadHistory() {
    try {
        const getURL = `${API_URLS.checkupUrls.uploadHistory}`;
        return await amplifyRequest(
            `${checkupBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostCheckup,
        );
    } catch (error) {
        throw error;
    }
}
/** Checkup APIs ends here */

/** Fringe APIs starts here */

export async function uploadFringeFile(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.fringeUrls.upload}?${queryString}`;
        return await amplifyRequest(
            `${fringeBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostFringe,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function uploadFringeFileComplete(body) {
    try {
        const postURL = `${API_URLS.fringeUrls.uploadComplete}`;
        return await amplifyRequest(
            `${fringeBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostFringe,
        );
    } catch (error) {
        throw error;
    }
}

export async function getFringeFilters() {
    try {
        const getURL = `${API_URLS.fringeUrls.filters}`;
        return await amplifyRequest(
            `${fringeBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostFringe,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function getFringeData(body) {
    try {
        const postURL = `${API_URLS.fringeUrls.data}`;
        return await amplifyRequest(
            `${fringeBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostFringe,
        );
    } catch (error) {
        throw error;
    }
}

export async function getFringeUploadHistory() {
    try {
        const getURL = `${API_URLS.fringeUrls.uploadHistory}`;
        return await amplifyRequest(
            `${fringeBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostCheckup,
        );
    } catch (error) {
        throw error;
    }
}

export async function getFringeForecast(body) {
    try {
        const postURL = `${API_URLS.fringeUrls.forecast}`;
        return await amplifyRequest(
            `${fringeBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostFringe,
        );
    } catch (error) {
        throw error;
    }
}

export async function getFringeForecastStatus(statusId) {
    try {
        const getURL = `${API_URLS.fringeUrls.forecast}/status?id=${statusId}`;
        return await amplifyRequest(
            `${fringeBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostCheckup,
        );
    } catch (error) {
        throw error;
    }
}

/** Fringe APIs ends here */

/** Vault APIs starts here */

export async function uploadVaultFile(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.vaultUrls.upload}?${queryString}`;
        return await amplifyRequest(
            `${vaultBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostVault,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function uploadVaultFileComplete(body) {
    try {
        const postURL = `${API_URLS.vaultUrls.uploadComplete}`;
        return await amplifyRequest(
            `${vaultBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostVault,
        );
    } catch (error) {
        throw error;
    }
}

export async function getVaultFilters() {
    try {
        const getURL = `${API_URLS.vaultUrls.filters}`;
        return await amplifyRequest(
            `${vaultBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostVault,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function getVaultForecast(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.vaultUrls.forecast}?${queryString}`;
        return await amplifyRequest(
            `${vaultBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostVault,
        );
    } catch (error) {
        throw error;
    }
}

/** Vault APIs ends here */

/** Accrucial APIs starts here */

export async function uploadAccrucialFile(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.accrucialUrls.upload}?${queryString}`;
        return await amplifyRequest(
            `${teAccrucialBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostTEAccrucial,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function uploadAccrucialFileComplete(body) {
    try {
        const postURL = `${API_URLS.accrucialUrls.upload}/complete`;
        return await amplifyRequest(
            `${teAccrucialBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostTEAccrucial,
        );
    } catch (error) {
        throw error;
    }
}

export async function getAccrucialForecast(body) {
    try {
        const postURL = `${API_URLS.accrucialUrls.forecast}`;
        return await amplifyRequest(
            `${teAccrucialBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostTEAccrucial,
        );
    } catch (error) {
        throw error;
    }
}

export async function getAccrucialForecastStatus(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.accrucialUrls.forecast}/status?${queryString}`;
        return await amplifyRequest(
            `${teAccrucialBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostTEAccrucial,
        );
    } catch (error) {
        throw error;
    }
}

// TE

export async function getTEAccrucialMasterData(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.accrucialUrls.te.masterData}?${queryString}`;
        return await amplifyRequest(
            `${teAccrucialBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostTEAccrucial,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function getTEAccrucialCharts(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.accrucialUrls.te.charts}?${queryString}`;
        return await amplifyRequest(
            `${teAccrucialBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostTEAccrucial,
        );
    } catch (error) {
        throw error;
    }
}

export async function getTEAccrucialExport(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.accrucialUrls.te.export}?${queryString}`;
        return await amplifyRequest(
            `${teAccrucialBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostTEAccrucial,
        );
    } catch (error) {
        throw error;
    }
}

// AIP

export async function getAIPAccrucialMasterData(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.accrucialUrls.aip.masterData}?${queryString}`;
        return await amplifyRequest(
            `${aipAccrucialBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostAIPAccrucial,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function getAIPAccrucialExport(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.accrucialUrls.aip.export}?${queryString}`;
        return await amplifyRequest(
            `${aipAccrucialBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostAIPAccrucial,
        );
    } catch (error) {
        throw error;
    }
}

export async function getAIPAccrucialCharts(body) {
    try {
        const postURL = `${API_URLS.accrucialUrls.aip.charts}`;
        return await amplifyRequest(
            `${aipAccrucialBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostAIPAccrucial,
        );
    } catch (error) {
        throw error;
    }
}

export async function getAIPAccrucialHistoricalFiles(body) {
    try {
        const postURL = `${API_URLS.accrucialUrls.aip.files}`;
        return await amplifyRequest(
            `${aipAccrucialBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostAIPAccrucial,
        );
    } catch (error) {
        throw error;
    }
}

export async function putAIPAccrucialBooked(body) {
    try {
        const putURL = `${API_URLS.accrucialUrls.aip.booked}`;
        return await amplifyRequest(
            `${aipAccrucialBaseUrl}${putURL}`,
            "PUT",
            body,
            environment.apiGatewayHostAIPAccrucial,
        );
    } catch (error) {
        throw error;
    }
}

export async function getAIPAccrucialAttainmentHistory(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.accrucialUrls.aip.attainmentHistory}?${queryString}`;
        return await amplifyRequest(
            `${aipAccrucialBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostAIPAccrucial,
        );
    } catch (error) {
        throw error;
    }
}

// Budget Entry
export async function runAIPAccrucialWorkflowBudgetEntry(body) {
    try {
        const postURL = `${API_URLS.accrucialUrls.aip.be_script}`;
        return await amplifyRequest(
            `${aipAccrucialBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostAIPAccrucial,
        );
    } catch (error) {
        throw error;
    }
}

export async function getAIPAccrucialBudgetEntryExportFiles(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.accrucialUrls.aip.be_export_files}?${queryString}`;
        return await amplifyRequest(
            `${aipAccrucialBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostAIPAccrucial,
        );
    } catch (error) {
        throw error;
    }
}

export async function getAIPAccrucialBudgetEntryExport(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.accrucialUrls.aip.be_export}?${queryString}`;
        return await amplifyRequest(
            `${aipAccrucialBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostAIPAccrucial,
        );
    } catch (error) {
        throw error;
    }
}

// FBP

export async function getFBPAccrucialMasterData(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.accrucialUrls.fbp.masterData}?${queryString}`;
        return await amplifyRequest(
            `${fbpAccrucialBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostFBPAccrucial,
        );
    } catch (error) {
        throw Error(error);
    }
}

/** Accrucial APIs ends here */

/** AWS Cloud Cost Forecast APIs starts here */

export async function getAWSCCForecastMasterData(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.awsCCUrls.masterData}?${queryString}`;
        return await amplifyRequest(
            `${awsCCForecastBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostAWSCCForecast,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function getAWSCCForecastExport(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.awsCCUrls.export}?${queryString}`;
        return await amplifyRequest(
            `${awsCCForecastBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostAWSCCForecast,
        );
    } catch (error) {
        throw error;
    }
}

export async function getAWSCCForecast() {
    try {
        const getURL = `${API_URLS.awsCCUrls.forecast}`;
        return await amplifyRequest(
            `${awsCCForecastBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostAWSCCForecast,
        );
    } catch (error) {
        throw error;
    }
}

export async function getAWSCCForecastStatus(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.awsCCUrls.forecast}/status?${queryString}`;
        return await amplifyRequest(
            `${awsCCForecastBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostAWSCCForecast,
        );
    } catch (error) {
        throw error;
    }
}

export async function getAWSCCForecastHistoricalFiles(body) {
    try {
        const postURL = `${API_URLS.awsCCUrls.files}`;
        return await amplifyRequest(
            `${awsCCForecastBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostAWSCCForecast,
        );
    } catch (error) {
        throw error;
    }
}

export async function putAWSCCForecastBooked(body) {
    try {
        const putURL = `${API_URLS.awsCCUrls.booked}`;
        return await amplifyRequest(
            `${awsCCForecastBaseUrl}${putURL}`,
            "PUT",
            body,
            environment.apiGatewayHostAWSCCForecast,
        );
    } catch (error) {
        throw error;
    }
}

export async function getAWSCCForecastPluggedCost(body) {
    try {
        const postURL = `${API_URLS.awsCCUrls.pluggedCost}`;
        return await amplifyRequest(
            `${awsCCForecastBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostAWSCCForecast,
        );
    } catch (error) {
        throw error;
    }
}

export async function updateAWSCCForecastPluggedCost(body) {
    try {
        const postURL = `${API_URLS.awsCCUrls.pluggedCost}/update`;
        return await amplifyRequest(
            `${awsCCForecastBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostAWSCCForecast,
        );
    } catch (error) {
        throw error;
    }
}

/** AWS Cloud Cost Forecast APIs ends here */

/** Tax Trend Analysis APIs starts here */

export async function uploadTaxTrendFile(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.taxTrendAnalysisUrls.upload}?${queryString}`;
        return await amplifyRequest(
            `${taxTrendAnalysisBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostTaxTrendAnalysis,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function uploadTaxTrendFileComplete(body) {
    try {
        const postURL = `${API_URLS.taxTrendAnalysisUrls.upload}/complete`;
        return await amplifyRequest(
            `${taxTrendAnalysisBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostTaxTrendAnalysis,
        );
    } catch (error) {
        throw error;
    }
}

export async function getTaxTrendAnalysisDropdowns() {
    try {
        const getURL = `${API_URLS.taxTrendAnalysisUrls.filters}`;
        return await amplifyRequest(
            `${taxTrendAnalysisBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostTaxTrendAnalysis,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function getTaxTrendAnalysisDashboard(body) {
    try {
        const postURL = `${API_URLS.taxTrendAnalysisUrls.dashboard}`;
        return await amplifyRequest(
            `${taxTrendAnalysisBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostTaxTrendAnalysis,
        );
    } catch (error) {
        throw error;
    }
}

/** Tax Trend Analysis APIs ends here */

/** Lease Recon APIs starts here */

export async function uploadLeaseReconFile(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.leaseReconUrls.upload}?${queryString}`;
        return await amplifyRequest(
            `${leaseReconBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostLeaseRecon,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function uploadLeaseReconFileComplete(body) {
    try {
        const postURL = `${API_URLS.leaseReconUrls.upload}/complete`;
        return await amplifyRequest(
            `${leaseReconBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostLeaseRecon,
        );
    } catch (error) {
        throw error;
    }
}

export async function getLeaseReconMasterData() {
    try {
        const getURL = `${API_URLS.leaseReconUrls.master_data}`;
        return await amplifyRequest(
            `${leaseReconBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostLeaseRecon,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function getLeaseReconRun(body) {
    try {
        const postURL = `${API_URLS.leaseReconUrls.run}`;
        return await amplifyRequest(
            `${leaseReconBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostLeaseRecon,
        );
    } catch (error) {
        throw error;
    }
}

/** Lease Recon APIs ends here */

/** ESG Dashboard APIs starts here */
export async function getESGDashboardMasterData() {
    try {
        const getURL = `${API_URLS.esgDashboardUrls.master_data}`;
        return await amplifyRequest(
            `${esgDashboardBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostESGDashboard,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function getESGDashboardExportData(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.esgDashboardUrls.presigned_url}?${queryString}`;
        return await amplifyRequest(
            `${esgDashboardBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostESGDashboard,
        );
    } catch (error) {
        throw Error(error);
    }
}
/** ESG Dashboard APIs ends here */

/** ACV Forecasting Dashboard APIs starts here */
export async function getACVForecastingDashboardFilters(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${
            API_URLS.acvForecastingDashboardUrls[queryObj.module].filters
        }?${queryString}`;
        return await amplifyRequest(
            `${acvForecastingDashboardBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostACVForecastingDashboard,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function getACVForecastingDashboardCharts(body) {
    try {
        const postURL = `${API_URLS.acvForecastingDashboardUrls[body.module].charts}`;
        return await amplifyRequest(
            `${acvForecastingDashboardBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostACVForecastingDashboard,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function getACVForecastingDashboardExport(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${
            API_URLS.acvForecastingDashboardUrls[queryObj.module].export
        }?${queryString}`;
        return await amplifyRequest(
            `${acvForecastingDashboardBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostACVForecastingDashboard,
        );
    } catch (error) {
        throw Error(error);
    }
}
/** ACV Forecasting Dashboard APIs ends here */

/** Forecast Platform APIs starts here */

export async function forecastPlatformUploadFile(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.forecastPlatformUrls.upload}?${queryString}`;
        return await amplifyRequest(
            `${forecastPlatformBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostForecastPlatform,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function forecastPlatformUploadFileComplete(body) {
    try {
        const postURL = `${API_URLS.forecastPlatformUrls.upload}/complete`;
        return await amplifyRequest(
            `${forecastPlatformBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostForecastPlatform,
        );
    } catch (error) {
        throw error;
    }
}

export async function forecastPlatformRunTrainModel(body) {
    try {
        const postURL = `${API_URLS.forecastPlatformUrls.run_train}`;
        return await amplifyRequest(
            `${forecastPlatformBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostForecastPlatform,
        );
    } catch (error) {
        throw error;
    }
}

export async function forecastPlatformRunForecastModel(body) {
    try {
        const postURL = `${API_URLS.forecastPlatformUrls.run_forecast}`;
        return await amplifyRequest(
            `${forecastPlatformBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostForecastPlatform,
        );
    } catch (error) {
        throw error;
    }
}

export async function forecastPlatformStatus(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.forecastPlatformUrls.status}?${queryString}`;
        return await amplifyRequest(
            `${forecastPlatformBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostForecastPlatform,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function forecastPlatformHistory(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.forecastPlatformUrls.history}?${queryString}`;
        return await amplifyRequest(
            `${forecastPlatformBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostForecastPlatform,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function forecastPlatformAction(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.forecastPlatformUrls.action}?${queryString}`;
        return await amplifyRequest(
            `${forecastPlatformBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostForecastPlatform,
        );
    } catch (error) {
        throw Error(error);
    }
}

/** Forecast Platform APIs ends here */

/** Applause Spend Platform APIs starts here */

export async function applauseSpendPlatformMasterData() {
    try {
        const getURL = `${API_URLS.applauseSpendPlatformUrls.masterData}`;
        return await amplifyRequest(
            `${applauseSpendPlatformBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostApplauseSpendPlatform,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function applauseSpendPlatformApplauseData(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.applauseSpendPlatformUrls.applauseData}?${queryString}`;
        return await amplifyRequest(
            `${applauseSpendPlatformBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostApplauseSpendPlatform,
        );
    } catch (error) {
        throw Error(error);
    }
}
/** Applause Spend Platform APIs ends here */

/** Payroll Cash Fund Forecast APIs starts here */

export async function payrollCashFundForecastMasterData() {
    try {
        const getURL = `${API_URLS.payrollCashFundUrls.masterData}`;
        return await amplifyRequest(
            `${payrollCashFundBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostPayrollCashFund,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function payrollCashFundForecastAdjustments(body) {
    try {
        const putURL = `${API_URLS.payrollCashFundUrls.adjustments}`;
        return await amplifyRequest(
            `${payrollCashFundBaseUrl}${putURL}`,
            "PUT",
            body,
            environment.apiGatewayHostPayrollCashFund,
        );
    } catch (error) {
        throw Error(error);
    }
}

/** Payroll Cash Fund Forecast APIs ends here */

/** Tax Recon APIs starts here */

export async function uploadTaxReconFile(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.taxReconUrls.upload}?${queryString}`;
        return await amplifyRequest(
            `${taxReconBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostTaxRecon,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function uploadTaxReconFileComplete(body) {
    try {
        const postURL = `${API_URLS.taxReconUrls.upload}/complete`;
        return await amplifyRequest(
            `${taxReconBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostTaxRecon,
        );
    } catch (error) {
        throw error;
    }
}

export async function getTaxReconMasterData() {
    try {
        const getURL = `${API_URLS.taxReconUrls.master_data}`;
        return await amplifyRequest(
            `${taxReconBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostTaxRecon,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function getTaxReconRun(body) {
    try {
        const postURL = `${API_URLS.taxReconUrls.run}`;
        return await amplifyRequest(
            `${taxReconBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostTaxRecon,
        );
    } catch (error) {
        throw error;
    }
}

export async function getTaxReconExportsHistory(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.taxReconUrls.exports}?${queryString}`;
        return await amplifyRequest(
            `${taxReconBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostTaxRecon,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function getTaxReconExport(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.taxReconUrls.export}?${queryString}`;
        return await amplifyRequest(
            `${taxReconBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostTaxRecon,
        );
    } catch (error) {
        throw Error(error);
    }
}

/** Tax Recon APIs ends here */

/** Seesaw APIs starts here */

export async function uploadSeesawFile(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.seesawUrls.upload}?${queryString}`;
        return await amplifyRequest(
            `${seesawBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostSeesaw,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function uploadSeesawFileComplete(body) {
    try {
        const postURL = `${API_URLS.seesawUrls.upload}/complete`;
        return await amplifyRequest(
            `${seesawBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostSeesaw,
        );
    } catch (error) {
        throw error;
    }
}

export async function getSeesawMasterData(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.seesawUrls.master_data}?${queryString}`;
        return await amplifyRequest(
            `${seesawBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostSeesaw,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function runSeesawSimulate(body) {
    try {
        const postURL = `${API_URLS.seesawUrls.simulation}`;
        return await amplifyRequest(
            `${seesawBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostSeesaw,
        );
    } catch (error) {
        throw error;
    }
}

export async function seesawStatus(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.seesawUrls.status}?${queryString}`;
        return await amplifyRequest(
            `${seesawBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostSeesaw,
        );
    } catch (error) {
        throw Error(error);
    }
}

/** Seesaw APIs ends here */

/** Meeting Digest APIs starts here */

export async function uploadMeetingDigestFile(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.meetingDigestUrls.upload}?${queryString}`;
        return await amplifyRequest(
            `${meetingDigestBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostMeetingDigest,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function uploadMeetingDigestFileComplete(body) {
    try {
        const postURL = `${API_URLS.meetingDigestUrls.upload}/complete`;
        return await amplifyRequest(
            `${meetingDigestBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostMeetingDigest,
        );
    } catch (error) {
        throw error;
    }
}

export async function processMeetingDigestFile(body) {
    try {
        const postURL = `${API_URLS.meetingDigestUrls.process}`;
        return await amplifyRequest(
            `${meetingDigestBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostMeetingDigest,
        );
    } catch (error) {
        throw error;
    }
}

export async function meetingDigestStatus(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.meetingDigestUrls.status}?${queryString}`;
        return await amplifyRequest(
            `${meetingDigestBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostMeetingDigest,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function getMeetingDigestExportsHistory(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.meetingDigestUrls.exports}?${queryString}`;
        return await amplifyRequest(
            `${meetingDigestBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostMeetingDigest,
        );
    } catch (error) {
        throw Error(error);
    }
}

export async function getMeetingDigestExport(queryObj) {
    try {
        const queryString = objectToQueryString(queryObj);
        const getURL = `${API_URLS.meetingDigestUrls.export}?${queryString}`;
        return await amplifyRequest(
            `${meetingDigestBaseUrl}${getURL}`,
            "GET",
            {},
            environment.apiGatewayHostMeetingDigest,
        );
    } catch (error) {
        throw Error(error);
    }
}

/** Meeting Digest APIs ends here  */

/** Admin apis */

export async function AddUserActivity(body) {
    try {
        const postURL = `${API_URLS.adminUrls.addUserActivity}`;
        return await amplifyRequest(
            `${adminBaseUrl}${postURL}`,
            "POST",
            body,
            environment.apiGatewayHostAdmin,
        );
    } catch (error) {
        throw error;
    }
}

/** Admin apis ends here */
