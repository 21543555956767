export const AutodeskModulesStrings = {
    PriceHub: "PriceHub",
    WatchDog: "WatchDog",
    SalesDashboard: "SalesDashboard",
    ExpenseDashboard: "ExpenseDashboard",
    BlackSwan: "Spotlight",
    Healthcheck: "Healthcheck Dashboard",
    Fringe: "Fringe",
    Vault: "Vault",
    TEAccrucial: "TEAccrucial",
    AIPAccrucial: "AIPAccrucial",
    FBPAccrucial: "FBPAccrucial",
    AWSCCForecast: "AWSCCForecast",
    TaxTrendAnalysis: "TaxTrendAnalysis",
    LeaseRecon: "LeaseRecon",
    ESGDashboard: "ESGDashboard",
    ACVForecastingDashboardTopline: "ACVForecastingDashboardTopline",
    ACVForecastingDashboardAMER: "ACVForecastingDashboardAMER",
    ForecastPlatform: "ForecastPlatform",
    ApplauseSpendPlatform: "ApplauseSpendPlatform",
    CashFundForecast: "CashFundForecast",
    TaxRecon: "TaxRecon",
    Seesaw: "Seesaw",
    MeetingDigest: "MeetingDigest",
    LumiTopics: "LumiTopics",
    Finbot: "FinBot",
    IRBot: "IRBot",
};

export const WatchDogTabs = {
    Search: 0,
    OWSAlert: 1,
};

export const WatchDogTabsStrings = {
    Search: "Search",
    OWSAlert: "OWS Alert",
};
