import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
const ChangePassword = lazy(() => import("./containers/ChangePassword"));
const ForgotPassword = lazy(() => import("./containers/ForgotPassword"));
const PriceHub = lazy(() => import("./containers/PriceHub"));
const WatchDog = lazy(() => import("./containers/WatchDog"));
const SalesDashboard = lazy(() => import("./containers/SalesDashboard"));
const ExpenseDashboard = lazy(() => import("./containers/ExpenseDashboard"));
const BlackSwan = lazy(() => import("./containers/BlackSwan"));
const Healthcheck = lazy(() => import("./containers/Healthcheck"));
const Fringe = lazy(() => import("./containers/Fringe"));
const Vault = lazy(() => import("./containers/Vault"));
const AboutUs = lazy(() => import("./containers/AboutUs"));
const NotFound = lazy(() => import("./components/NotFound"));
const DemoDashboards = lazy(() => import("./containers/DemoDashboards"));
const rakeshDemo = lazy(() => import("./containers/DemoDashboards/rakesh-demo"));
const TEAccrucial = lazy(() => import("./containers/AccrucialDashboard"));
const AIPAccrucial = lazy(() => import("./containers/AccrucialDashboard/AIPAccrucial"));
const AWSCCForecast = lazy(() => import("./containers/AWSCCForecast"));
const FBPAccrucial = lazy(() =>
    import("./containers/AccrucialDashboard/FBPAccrucial/fbp-accrucial"),
);
const TaxTrendAnalysis = lazy(() => import("./containers/TaxTrendAnalysis"));
const LeaseRecon = lazy(() => import("./containers/LeaseRecon"));
const ESGDashboard = lazy(() => import("./containers/ESGDashboard"));
const MerlinDashboardTopline = lazy(() =>
    import("./containers/MerlinForecastingDashboard/Topline"),
);
const MerlinDashboardAMER = lazy(() => import("./containers/MerlinForecastingDashboard/AMER"));
const ForecastPlatform = lazy(() => import("./containers/ForecastPlatform"));
const ForecastPlatformView = lazy(() => import("./containers/ForecastPlatform/forecast-view"));
const ApplauseSpendPlatform = lazy(() => import("./containers/ApplauseSpendPlatform"));
const PayrollCashFund = lazy(() => import("./containers/PayrollCashFund"));
const EMEATaxRecon = lazy(() => import("./containers/TaxRecon/EMEA"));
const Seesaw = lazy(() => import("./containers/SeeSaw"));
const MeetingDigest = lazy(() => import("./containers/MeetingDigest"));
const LumiTopics = lazy(() => import("./containers/LumiTopics"));
const FinBot = lazy(() => import("./containers/FinBot"));
const IRBot = lazy(() => import("./containers/IRBot"));

import PageLoader from "./components/PageLoader";

export const AppRouter = () => (
    <Suspense fallback={<PageLoader text="Please wait while we load your application" />}>
        <Switch>
            <Route path="/" exact={true} component={Home} />
            <Route path="/login" exact={true} component={Login} />
            <Route path="/change-password" exact={true} component={ChangePassword} />
            <Route path="/forgot-password" exact={true} component={ForgotPassword} />
            <Route path="/login/change-password" exact={true} component={ChangePassword} />
            <Route
                path="/forgot-password/change-password"
                exact={true}
                component={ChangePassword}
            />
            <Route path="/price-hub" exact={true} component={PriceHub} />
            <Route path="/watch-dog" exact={true} component={WatchDog} />
            <Route path="/sales-dashboard" exact={true} component={SalesDashboard} />
            <Route path="/expense-dashboard" exact={true} component={ExpenseDashboard} />
            <Route path="/spotlight" exact={true} component={BlackSwan} />
            <Route path="/checkup" exact={true} component={Healthcheck} />
            <Route path="/fringe" exact={true} component={Fringe} />
            <Route path="/vault" exact={true} component={Vault} />
            <Route path="/about-us" exact={true} component={AboutUs} />
            <Route path="/demo-dashboards" exact={true} component={DemoDashboards} />
            <Route path="/rakesh-demo" exact={true} component={rakeshDemo} />
            <Route path="/accrucial/te-accrucial" exact={true} component={TEAccrucial} />
            <Route path="/accrucial/aip-accrucial" exact={true} component={AIPAccrucial} />
            <Route path="/accrucial/fbp-accrucial" exact={true} component={FBPAccrucial} />
            <Route path="/aws-cc-forecast" exact={true} component={AWSCCForecast} />
            <Route path="/tax-trend-analysis" exact={true} component={TaxTrendAnalysis} />
            <Route path="/lease-recon" exact={true} component={LeaseRecon} />
            <Route path="/esg-dashboard" exact={true} component={ESGDashboard} />
            <Route
                path="/merlin-forecasting/topline"
                exact={true}
                component={MerlinDashboardTopline}
            />
            <Route path="/merlin-forecasting/amer" exact={true} component={MerlinDashboardAMER} />
            <Route path="/forecast-platform" exact={true} component={ForecastPlatform} />
            <Route path="/forecast-platform/view" exact={true} component={ForecastPlatformView} />
            <Route path="/applause-spend-platform" exact={true} component={ApplauseSpendPlatform} />
            <Route path="/payroll-cash-fund" exact={true} component={PayrollCashFund} />
            <Route path="/tax-recon/emea" exact={true} component={EMEATaxRecon} />
            <Route path="/seesaw" exact={true} component={Seesaw} />
            <Route path="/meeting-digest" exact={true} component={MeetingDigest} />
            <Route path="/lumi-topics" exact={true} component={LumiTopics} />
            <Route path="/finbot" exact={true} component={FinBot} />
            <Route path="/irbot" exact={true} component={IRBot} />
            <Route component={NotFound} />
        </Switch>
    </Suspense>
);
